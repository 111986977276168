import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import useLiveControl from "../../controlled/useLiveControl"

const RemodelingFinancingPage = () => {
  // const { isStaging } = useLiveControl()
  return (
    <Layout>
      <Seo
        title={"Home Remodel Financing For Contractors"}
        description={
          "Stop losing out on job opportunities and give your customers what they want with Enerbank&#39;s home remodel financing for contractors."
        }
      />
      <Helmet>
        <body className="page-remodeling-financing industry" />
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "Home Remodel Financing For Contractors",
 "image": "",
 "description": "We know remodeling projects can be stressful for everyone involved, and that's why it's our goal to provide the best possible experience to both you and your customers. With technology and loan products designed specifically for home renovation, you can rest assured that you and the homeowner are in good hands throughout the process."
 }`}
        </script>
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="remodeling-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "https://gbdevpress.enerbank.com/wp-content/uploads/2020/05/hero-img.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Home Remodel Financing For Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="justify-content-center">
            <p>
              The remodeling industry is unique, and you may not have the time
              or resources to create a financing program of your own to fit both
              the needs of your customers and your business. That&#39;s why
              linking up with a financing provider can be a great step forward
              for your business.
            </p>
          </div>
        </div>
      </section>
      <div className="benefits-head">
        <h2 className="text-center bg-blue">
          Signs That You Need To Offer Financing For Clients
        </h2>
      </div>
      <section className="financing-benefits">
        <div className="container">
          <div className="row pb-5">
            <div className="col-12 justify-content-center">
              <p className="pb-5">
                Have you found your contracting business struggling to close
                deals or attract new customers? You&#39;re not alone. Here are
                some common issues that contractors are experiencing in this
                busy and competitive market as well as how offering payment
                options can help negate them.
              </p>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/financing.png"
                    class=""
                    alt="money in hand icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Customers Want Financing</h3>
                  <p className="card-text">
                    Have your customers expressed interest in financing their
                    projects, but you don&#39;t have the time and resources to
                    develop your own program? EnerBank has you covered. With
                    comprehensive loan products designed specifically for home
                    renovation, there&#39;s a fit for every homeowner.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/losing-out-jobs.png"
                    class=""
                    alt="no workers icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Losing Out On Jobs</h3>
                  <p className="">
                    It&#39;s tough losing a job, especially when it&#39;s to
                    your less-experienced competition, just because they
                    advertise payment options. Research has shown that by
                    including payment options as a part of your marketing, you
                    can see a 50%<sup>*</sup> increase in leads and a better
                    conversion rate.
                    <br />
                    <small>
                      *Based on the Brickyard Study conducted by EnerBank USA.
                      2018.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/bad-experience.png"
                    class=""
                    alt="thumbs down icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Previous Bad Experience</h3>
                  <p className="">
                    Not happy with your current payment options provider or have
                    had a bad experience in the past? EnerBank is different, and
                    here&#39;s why: we&#39;re experts in home renovation &#8212;
                    that means we can provide personalized service that you
                    won&#39;t find elsewhere. Your dedicated relationship
                    manager will be there to assist you with everything from
                    business goals to branded marketing materials.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why-enerbank">
        <div className="container">
          <div className="row">
            <div className="col-12 justify-content-center">
              <h2 className="text-center">
                Why Work with EnerBank for Home Remodel Financing?
              </h2>
              <p className="pb-5">
                We&#39;re not your run-of-the-mill lender. Over the past 20
                years, we&#39;ve funded nearly a million home improvement
                projects to the tune of over $12 billion, providing specialized
                support to home improvement professionals and homeowners. This
                means we&#39;re not only experts in our industry, but we&#39;re
                an expert in yours too. Here are just some of the things that
                set us apart from the competition:
              </p>
            </div>
          </div>
          <div className="row pb-5 justify-content-center">
            <div className="col-md-5">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/application-icon.png"
                    class=""
                    alt="signature icon"
                  />
                </div>
                <div className="card-body">
                  <h3>A Choice of Application Methods</h3>
                  <p className="card-text">
                    We&#39;ve developed three easy, paperless ways for your
                    customers to apply for financing. This includes applying
                    online, through our app, or over the phone, and it also
                    means no extra paperwork for you. Plus, the API can be
                    integrated into your sales process, so it&#39;s as easy and
                    convenient as possible.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/dedicated-rm.png"
                    class=""
                    alt="handsake icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Dedicated Relationship Manager</h3>
                  <p className="">
                    Like an extra team member on your crew, looking out for you
                    and your business, your dedicated relationship manager is
                    committed to your business and will provide you with the
                    support you need to be successful.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-5 justify-content-center">
            <div className="col-md-5">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/marketing-materials.png"
                    class=""
                    alt="mail icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Customized Marketing Materials</h3>
                  <p className="">
                    EnerBank provides high-quality marketing assets and other
                    resources, including top-notch training designed to make
                    offering payment options easier and more effective. This
                    includes website integration, emails, flyers, and more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <div className="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/04/excellent-experience.png"
                    class=""
                    alt="champion icon"
                  />
                </div>
                <div className="card-body">
                  <h3>Excellent Customer Experience</h3>
                  <p className="">
                    We know remodeling projects can be stressful for everyone
                    involved, and that&#39;s why it&#39;s our goal to provide
                    the best possible experience to both you and your customers.
                    With technology and loan products designed specifically for
                    home renovation, you can rest assured that you and the
                    homeowner are in good hands throughout the process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faqs" className="bg-light-gray remodeling-faqs">
        <div className="container">
          <h2 className="text-center pb-4">Frequently Asked Questions</h2>
          <div className="pt-5">
            <dl>
              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    What Kinds of Home Remodeling Can Be Financed?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    The lending companies that specialize in home improvement
                    financing can cover a variety of home improvement projects.
                    This can be everything from the roof down to the foundation,
                    and out into the yard. During your research, ensure that
                    whatever company you&#39;re looking at provides funds for
                    your line of work. Since EnerBank specializes in home
                    improvement financing we can help just about any successful
                    contractor offer financing to their customers.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Can a Home Remodeling Contractor Offer Financing?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    The first step is to establish a relationship with a lender.
                    Do your research to find someone who provides the right
                    tools and most aligns with your growth goals. Once you have
                    a lender, create a strategy to implement financing into your
                    sales process. The most important aspect of offering
                    financing is making sure it&#39;s included in every bid you
                    present. The more of a routine you make this, the more
                    likely you are to enjoy the benefits that financing offers.
                    Make homeowners aware of financing by mentioning it in all
                    your marketing materials and on your website.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    What Are the Benefits to a Contractor to Offer Financing?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    Contractor financing provides an easy way for your customers
                    to pay for their projects without them having to seek
                    outside funding. Offering financing can help you increase
                    your leads, grow your average project size, close more
                    deals, and more. Customers appreciate the ease and
                    convenience that financing affords them, allowing them to
                    get the project they want now without having to make
                    compromises.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Soon Does a Remodeler Get Paid when Customers Get
                    Financing and Pay Later?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    It depends on your lending partner. Some financing companies
                    will send the funds to the homeowner who then pays you while
                    others will deposit the funds directly to your account. Make
                    sure you understand the process before signing on with a
                    company. EnerBank usually get the money to the contractors
                    within one business day after the loan has been approved.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Can I Know Which is the Best Remodeling Finance Company?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    As a contractor, working with a finance company that has a
                    firm understanding of the home improvement industry, like
                    EnerBank, can make all the difference. The loans and tools
                    they have available can fit right into your business and
                    strengthen the foundation your business has already built.
                    You can also take advantage of any additional resources they
                    have to help make your team and business the best it can be.
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
      <section className="start-financing pt-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white pb-0">
              Start Offering Home Remodel Financing!
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-60">
              <p className="pb-4">
                Have you asked yourself, "How can I{" "}
                <Link
                  className="eb-link"
                  to="/contractor-financing/what-is-contractor-financing/"
                >
                  offer contractor financing to my clients
                </Link>{" "}
                ?" Fortunately, it&#39;s easy to get started. Click the button
                below to fill out the form, and a member of our team will get
                back to you within two business days. Yep, it&#39;s that simple.
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <p className="text-center mb-0">
                <a
                  className="btn btn-primary"
                  style={{
                    padding: "0.75rem",
                  }}
                  href="/sign-up/"
                >
                  Get Started Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RemodelingFinancingPage
